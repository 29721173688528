import React, { useState, useEffect } from "react"; // Import useState and useEffect
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import Toolstack from "./Toolstack";
import ScubaDiver from "../../Assets/ScubaDiver.png"; // Path to your scuba diver image
import "./ScubaDiverAnimation.css"; // Add a custom CSS file for styles
import CyberStack from "./CyberSecurity";
import Reviews from "./Reviews";

function About() {
  const [bubbles, setBubbles] = useState([]); // State to hold bubbles

  // Function to add a new bubble
  const addBubble = () => {
    const newBubble = {
      size: Math.random() * 10 + 5, // Size between 5 and 15
      left: Math.random() * 100 + "%", // Random left position
      animationDuration: Math.random() * 3 + 2 + "s", // Duration between 2 and 5 seconds
      delay: Math.random() * 5 + "s", // Random delay before the bubble appears
    };
    setBubbles((prevBubbles) => [...prevBubbles, newBubble]);
  };

  // Use useEffect to automatically add bubbles at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      addBubble();
    }, 1000); // Add a bubble every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="blue">About Me</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "30px", paddingBottom: "50px" }} // Adjusted padding
            className="about-img"
          >
            <div className="scuba-diver-container">
              <img src={ScubaDiver} alt="scuba diver" className="img-fluid scuba-diver" />
              {bubbles.map((bubble, index) => (
                <div
                  key={index}
                  className="bubble"
                  style={{
                    width: `${bubble.size}px`,
                    height: `${bubble.size}px`,
                    left: bubble.left,
                    animationDuration: bubble.animationDuration,
                    animationDelay: bubble.delay,
                  }}
                ></div>
              ))}
            </div>
          </Col>
        </Row>

        <h1 className="project-heading" style={{ textAlign: "center" }}>
          Professional <strong className="blue">Skillsets</strong>
        </h1>

        <Techstack />

        <h1 className="project-heading" style={{ textAlign: "center" }}>
          <strong className="blue">Tools </strong> I use
        </h1>
        <Toolstack />

        <Github />
        <br></br>
        <br></br>
        <h1 className="CyberSecurity" style={{ textAlign: "center" }}>
          <strong className="blue">Certifcates </strong>I am pursuing.
        </h1>

        <div className="certificates-container" style={{ textAlign: "center", margin: "30px 0" }}>
          <CyberStack />
          <p style={{ textAlign: "center", fontSize: "1.2em", lineHeight: "1.6", color: "#d8e0ed", maxWidth: "800px", margin: "0 auto" }}>
            I am currently pursuing the Certified Bug Bounty Hunter (CBBH) certificate through Hack The Box. The certification is designed to provide in-depth knowledge and practical experience in identifying and exploiting vulnerabilities in web applications. I am 40% through the certification process and have already gained valuable skills that will help me excel in the world of bug bounty hunting.
            <br /><br />
            The CBBH certification is one of the most respected in the cybersecurity community, and completing it will allow me to further sharpen my skills and contribute to the security community by responsibly disclosing vulnerabilities.
          </p>
        </div>

        <div><Reviews></Reviews></div>

        
      </Container>
    </Container>
  );
}

export default About;

