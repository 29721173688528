import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { FaPlane, FaShieldAlt, FaFutbol } from "react-icons/fa";

function AboutCard() {
  return (
    <Card
      className="quote-card-view"
      style={{
        border: "none",
        borderRadius: "15px",
        backgroundColor: "#f9f9f9",
        padding: "20px",
      }}
    >
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify", fontSize: "1.2rem", lineHeight: "1.6" }}>
            <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Hello there!</span>
            <br />
            I'm <span className="blue" style={{ color: "#007BFF" }}>Mark Moore</span>, based in{" "}
            <span className="blue" style={{ color: "#00BFFF" }}>Belfast, UK</span>.
            <br />
            I'm currently studying <span style={{ fontWeight: "bold", color: "#FF6347" }}>
              Software Engineering</span> at Queen's University. My journey has been all about combining my technical skills with creativity to solve real-world problems.
            <br />
            <br />
            My fascination with <span style={{ fontWeight: "bold", color: "#FF6347" }}>
              Cyber Security</span> drives me to explore ways to protect and defend digital systems. From ethical hacking to network security, I’m passionate about securing the ever-evolving world of technology.
            <br />
            <br />
            Outside of my academic and technical pursuits, I'm an active and adventurous person. Here's a bit more about me:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight style={{ color: "#007BFF" }} /> <FaFutbol style={{ marginRight: 5 }} /> Playing Football (Soccer) - both recreational and competitive.
            </li>
            <li className="about-activity">
              <ImPointRight style={{ color: "#007BFF" }} /> <FaShieldAlt style={{ marginRight: 5 }} /> Scuba Diving - I love exploring the underwater world and its mysteries.
            </li>
            <li className="about-activity">
              <ImPointRight style={{ color: "#007BFF" }} /> <FaPlane style={{ marginRight: 5 }} /> Traveling - whether it's exploring new cultures or getting lost in nature, I’m always up for an adventure.
            </li>
          </ul>

          <p style={{ fontStyle: "italic", marginTop: "1.5rem", color: "#fffff" }}>
            "I believe that curiosity fuels growth, and every challenge is an opportunity to innovate and learn."{" "}
          </p>
          <footer className="blockquote-footer" style={{ marginTop: "1rem" }}>
            <cite title="Source Title">Mark Moore</cite>
          </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
