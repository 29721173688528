import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";

const reviews = [
  {
    name: "John Doe",
    text: "The team did an excellent job with our website, delivering a professional and easy-to-use product.",
    role: "CEO, TechCorp"
  },
  {
    name: "Jane Smith",
    text: "Their attention to detail and commitment to quality is unparalleled. Highly recommend!",
    role: "Project Manager, WebStudio"
  },
  {
    name: "Michael Brown",
    text: "I’m thrilled with the final product. It was a smooth experience from start to finish.",
    role: "Founder, StartUp Inc."
  }
];

function Reviews() {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const handleClick = (index) => {
    setCurrentReviewIndex(index);
  };

  return (
    <div className="reviews-section" style={{ padding: "60px 0", textAlign: "center" }}>
      <h2 style={{ marginBottom: "40px", color: "White", fontWeight: "600", fontSize: "2rem" }}>
        Testimonials
      </h2>

      <Row className="justify-content-center" style={{ display: "flex", justifyContent: "center" }}>
        {reviews.map((review, index) => {
          const isActive = index === currentReviewIndex;

          return (
            <Col key={index} xs={12} md={4} style={{ padding: "10px" }}>
              <Card
                onClick={() => handleClick(index)}
                style={{
                  border: "none",
                  borderRadius: "12px",
                  boxShadow: isActive
                    ? "0 12px 24px rgba(0, 0, 0, 0.2)" // Strong shadow for active card
                    : "0 4px 12px rgba(0, 0, 0, 0.1)", // Lighter shadow for inactive cards
                  background: isActive
                    ? "linear-gradient(145deg,rgb(0, 60, 212), #8eecf5)" // Light blue gradient for active card
                    : "linear-gradient(145deg,rgb(33, 32, 47),rgb(129, 134, 134))", // Subtle background for inactive cards
                  transition: "transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease", // Smooth transitions
                  cursor: "pointer",
                  padding: "30px",
                  opacity: isActive ? 1 : 0.8, // Fade inactive cards slightly
                  transform: isActive ? "scale(1.05)" : "scale(1)", // Slightly scale up active card
                  height: "300px", // Fixed height for all cards
                  display: "flex",
                  flexDirection: "column", // Ensures text stays aligned
                  justifyContent: "space-between", // Keeps the content spaced evenly
                  borderLeft: isActive ? "5px solidrgb(229, 230, 231)" : "none", // Blue left border for active card
                  borderTop: isActive ? "5px solidrgb(255, 255, 255)" : "none", // Optional top border for active card
                }}
              >
                <Card.Body>
                  <blockquote className="blockquote mb-4" style={{ fontStyle: "italic", fontSize: "1.1rem", color: "darkblue" }}>
                    <p>"{review.text}"</p>
                    <footer className="blockquote-footer" style={{ fontWeight: "bold", fontSize: "1rem" }}>
                      {review.name} <cite title="Source Title" style={{ fontWeight: "normal" }}>
                        ({review.role})
                      </cite>
                    </footer>
                  </blockquote>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default Reviews;
