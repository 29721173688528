import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MarkFlyer from '../../Assets/MarkFlyer.pdf'; // Path to your PDF file
import Particle from '../Particle'; // Your existing particle component
import './Volunteering.css'; // Your CSS file

function Volunteering() {
  return (
    <Container fluid className="volunteering-section">
      {/* Apply a container class for particles */}
      <div className="particle-container">
        <Particle />
      </div>
      <Container>
        <Row style={{ justifyContent: 'center', padding: '20px' }}>
          <Col md={6} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <h1 style={{ fontSize: '2.5em', fontWeight: 'bold' }}>
              <strong className="blue">Volunteering Services</strong>
            </h1>
            <p style={{ fontSize: '1.2em', lineHeight: '1.8', color: '#d8e0ed' }}>
              I offer free assistance to seniors in my local area to help them with day-to-day tasks, digital literacy, and companionship. 
              Whether it's setting up a smartphone, running errands, or simply having a friendly conversation, I'm here to help.
              <br></br>
              <br />
            </p>
          </Col>
        </Row>

        {/* PDF Display Section */}
        <Row style={{ justifyContent: 'center', padding: '20px' }}>
          <Col md={12} className="pdf-container">
            <h2 style={{ textAlign: 'center', color: '#0d6efd' }}>View My Flyer</h2>
            <iframe 
              src={MarkFlyer} 
              width="100%" 
              height="900px" 
              style={{ border: 'none' }}
              title="PDF Flyer"
            />
            <p style={{ textAlign: 'center' }}>
              <a href={MarkFlyer} download style={{ color: '#0d6efd' }}>Download Flyer</a>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Volunteering;
