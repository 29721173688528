import React from "react";
import Card from 'react-bootstrap/Card';

function CyberStack() {
  return (
    <div style={{ padding: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      
      {/* Card 1 */}
      <Card 
        style={{
          width: '250px',
          backgroundImage: 'linear-gradient(145deg, rgb(0, 60, 212), #8eecf5)',
          color: 'white',
          border: 'none',
          padding: '15px',
          margin: '15px'
        }}
      >
        <Card.Body>
          <Card.Title>CompTIA Security+</Card.Title>
          <Card.Text>
            Date Started: 13/01/2025<br />
            Current Comments: 
          </Card.Text>
        </Card.Body>
        <Card.Footer style={{ background: 'rgba(255, 255, 255, 0.2)', borderTop: 'none' }}>
          <small style={{ color: 'white' }}>Last updated 3 mins ago</small>
        </Card.Footer>
      </Card>

      {/* Card 2 */}
      <Card 
        style={{
          width: '250px',
          backgroundImage: 'linear-gradient(145deg, #5f0a87, #a4508b)',
          color: 'white',
          border: 'none',
          padding: '15px',
          margin: '15px'
        }}
      >
        <Card.Body>
          <Card.Title>Certified Bug Bounty Course</Card.Title>
          <Card.Text>
            This card has supporting text below as a natural lead-in to
            additional content.
          </Card.Text>
        </Card.Body>
        <Card.Footer style={{ background: 'rgba(255, 255, 255, 0.2)', borderTop: 'none' }}>
          <small style={{ color: 'white' }}>Last updated 3 mins ago</small>
        </Card.Footer>
      </Card>

      {/* Card 3 */}
      <Card 
        style={{
          width: '250px',
          backgroundImage: 'linear-gradient(145deg, #008080, #3eb489)',
          color: 'white',
          border: 'none',
          padding: '15px',
          margin: '15px'
        }}
      >
        <Card.Body>
          <Card.Title>Advanced Cyber Security Training</Card.Title>
          <Card.Text>
            This is a wider card with supporting text below as a natural lead-in
            to additional content. This card has even longer content than the
            first to show that equal height action.
          </Card.Text>
        </Card.Body>
        <Card.Footer style={{ background: 'rgba(255, 255, 255, 0.2)', borderTop: 'none' }}>
          <small style={{ color: 'white' }}>Last updated 3 mins ago</small>
        </Card.Footer>
      </Card>

    </div>
  );
}

export default CyberStack;
