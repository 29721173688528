import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiIntellijidea,
  SiEclipseide,
  SiXcode,
  SiDocker,
  SiPostman,
  SiFigma,
  SiTrello,
  SiSlack,
  SiNotion,
  SiJira,
  SiObsidian,
} from "react-icons/si";

const tools = [
  { icon: <SiVisualstudiocode /> },
  { icon: <SiIntellijidea /> },
  { icon: <SiEclipseide /> },
  { icon: <SiXcode /> },
  { icon: <SiDocker /> },
  { icon: <SiPostman /> },
  { icon: <SiFigma /> },
  { icon: <SiTrello /> },
  { icon: <SiSlack />},
  { icon: <SiNotion /> },
  { icon: <SiJira />},
  { icon: <SiObsidian /> },
];

function Toolstack() {
  return (
    <div style={{ padding: "20px 0" }}>
      <h2 style={{ textAlign: "center", marginBottom: "30px", color: "#007BFF" }}>
        My Tool Stack
      </h2>
      <Row style={{ justifyContent: "center", paddingBottom: "50px", rowGap: "20px" }}>
        {tools.map((tool, index) => (
          <Col key={index} xs={4} md={2} className="tech-icons">
            {tool.icon}
            <p style={{ fontSize: "12px", textAlign: "center", marginTop: "5px" }}>
              {tool.name}
            </p>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Toolstack;
