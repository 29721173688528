import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently, showcasing my skills in a variety of technologies and domains.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="French Escape Room"
              description="A fun and interactive virtual escape room platform designed to connect friends and teams. Built with React.js, Material-UI, and Firebase, this project offers features like real-time chat, media sharing, and user reactions to enhance collaboration and fun during the game."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Ski Trip Booking System"
              description="An intuitive booking platform for planning and managing ski trips, developed with Next.js and Tailwind CSS. It pulls content dynamically from markdown files for seamless updates, supports dark mode, and provides an easy-to-use interface for users to browse available trips and make bookings."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Hotel System"
              description="A fully functional online code editor that supports HTML, CSS, and JavaScript with real-time preview, developed in React.js. It features markdown editing capabilities for creating README files, complete with custom HTML tags, a toolbar, and an auto-saving feature using local storage for user convenience."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Raspberry Pi Live Encrypted Camera Feed"
              description="Leveraged the power of PyTorch to train an image classifier for plant disease detection. Using a dataset from Kaggle, the model was built on CNN and transfer learning, achieving 98% accuracy with the ResNet34 pretrained model. This project also involved live-streaming encrypted plant disease data through a Raspberry Pi."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Museum System"
              description="A project aimed at preventing suicide through advanced NLP techniques. It analyzes social media posts to detect suicide-related content and provides interventions based on the identified user's emotional state. The system strives to provide timely support for at-risk individuals."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Ducky Scripts for a Flipper Zero"
              description="Trained a convolutional neural network (CNN) using the FER-2013 dataset to predict human emotions from facial expressions. With an accuracy of 60.1%, the classifier uses OpenCV to detect faces in images, then feeds them into the trained model to predict emotions like happiness, sadness, anger, and more."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
