import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import { DiReact, DiJava, DiGit, DiJavascript1, DiHtml5, DiCss3 } from "react-icons/di";
import { SiCsharp, SiFlutter, SiPostgresql, SiMysql } from "react-icons/si";

function Techstack() {
  return (
    <div style={{ padding: "20px 0" }}>
      <h2 style={{ textAlign: "center", marginBottom: "30px", color: "#007BFF" }}>
        My Tech Stack
      </h2>
      <Row style={{ justifyContent: "center", paddingBottom: "50px", rowGap: "20px" }}>
        <Col xs={4} md={2} className="tech-icons">
          <CgCPlusPlus style={{ fontSize: "3rem", color: "#00599C" }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiReact style={{ fontSize: "3rem", color: "#61DAFB" }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiJavascript1 style={{ fontSize: "3rem", color: "#F7DF1E" }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiCsharp style={{ fontSize: "3rem", color: "#9C499F" }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiFlutter style={{ fontSize: "3rem", color: "#02569B" }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiJava style={{ fontSize: "3rem", color: "#007396" }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiHtml5 style={{ fontSize: "3rem", color: "#E34F26" }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiCss3 style={{ fontSize: "3rem", color: "#1572B6" }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiPostgresql style={{ fontSize: "3rem", color: "#336791" }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <SiMysql style={{ fontSize: "3rem", color: "#00758F" }} />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
          <DiGit style={{ fontSize: "3rem", color: "#F05032" }} />
        </Col>
      </Row>
    </div>
  );
}

export default Techstack;
